.landingLayout {
    background-color: var(--service-backround-color);
}

.landingLayout__app {
    width: 100vw;
    height: 100vh;
    padding: 20px 40px;
    overflow: scroll;
}
