.title {
    width: 100%;
    font-size: 24px;
    text-align: center;
}

.page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.filters {
    display: flex;
    gap: 20px;
}

.buttons {
    display: flex;
    gap: 20px;
}
