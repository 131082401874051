@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Black.otf');
  font-weight: 900;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Heavy.otf');
  font-weight: 800;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf');
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf');
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Light.otf');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Thin.otf');
  font-weight: 200;
  font-display: fallback;
}


@font-face {
  font-family: SFPro;
  src: url('./assets/fonts/SF-Pro-Display-Ultralight.otf');
  font-weight: 100;
  font-display: fallback;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.App {
  font-family: var(--main-font);
  overflow: hidden;
  /*height: 100vh;*/
}

.darkTheme .App {
  background-color: var(--service-backround-color);
}

.cm-scroller,
.cm-editor {
  border-radius: 10px;
}
